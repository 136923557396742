<template>
  <div class="search">
    <div style="position: relative;">
      <div class="loupe" />
      <input
        id="search"
        ref="ssearch"
        type="search"
        name="search"
        v-model="searchQuery"
        :class="{ active: searchQuery }"
        @input="trottled"
        @focus="focused = true"
        @blur="focused = false"
        autocomplete="off"
        :placeholder="lang('search', 'ru')"
      >
      <!-- <div v-if="searchQuery.length" @click="searchQuery = ''" class="clr"></div> -->
    </div>
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.artists.length &&
        !searchHints.albums.length &&
        !searchHints.tracks.length"
        class="no-match"
      >
        <span>Ничего не найдено</span>
      </div>
      <div v-else>
        <div v-if="searchHints.radio.length">
          <h4>{{ lang('radio', 'ru') }}</h4>
          <ul>
            <li
              v-for="(item, i) in searchHints.radio"
              :key="`radio-${item.id}-${i}`"
            >
              <div class="poster">
                <img
                  :data-src="item.cover"
                  src="/static_files/no-poster.png"
                  alt=""
                  class="lazyload"
                >
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span style="text-transform: uppercase;">{{ item.singer }}</span>
              </p>
              <nuxt-link :to="`/collection/radio/${item.id}`"></nuxt-link>
            </li>
          </ul>
        </div>
        <div v-if="searchHints.genres.length">
          <h4>{{ lang('genres', 'ru') }}</h4>
          <ul>
            <li
              v-for="(item, i) in searchHints.genres"
              :key="`genre-${item.id}-${i}`"
              :class="{ active: $route.path === `/collection/genres/${item.id}` }"
            >
              <div class="poster">
                <img
                  :data-src="item.cover"
                  src="/static_files/no-poster.png"
                  alt=""
                  class="lazyload"
                >
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span style="text-transform: uppercase;">{{ item.singer }}</span>
              </p>
              <nuxt-link :to="`/collection/genres/${item.id}`"></nuxt-link>
            </li>
          </ul>
        </div>
        <div v-if="searchHints.collections.length">
          <h4>{{ lang('collections', 'ru') }}</h4>
          <ul>
            <li
              v-for="(item, i) in searchHints.collections"
              :key="`collection-${item.id}-${i}`"
              :class="{ active: $route.path === `/collection/collections/${item.id}` }"
            >
              <div class="poster">
                <img
                  :data-src="item.cover"
                  src="/static_files/no-poster.png"
                  alt=""
                  class="lazyload"
                >
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span style="text-transform: uppercase;">{{ item.singer }}</span>
              </p>
              <nuxt-link :to="`/collection/collections/${item.id}`"></nuxt-link>
            </li>
          </ul>
        </div>
        <div v-if="searchHints.artists.length">
          <h4>{{ lang('artists', 'ru') }}</h4>
          <ul>
            <li
              v-for="(item, i) in searchHints.artists"
              :key="`artist-${item.id}-${i}`"
              :class="{ active: $route.path === `/collection/artists/${item.id}` }"
            >
              <div class="poster">
                <img
                  :data-src="item.cover"
                  src="/static_files/no-poster.png"
                  alt=""
                  class="lazyload"
                >
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span style="text-transform: uppercase;">{{ item.singer }}</span>
              </p>
              <nuxt-link :to="`/collection/artists/${item.id}`"></nuxt-link>
            </li>
          </ul>
        </div>
        <div v-if="searchHints.albums.length">
          <h4>{{ lang('albums', 'ru') }}</h4>
          <ul>
            <li
              v-for="(item, i) in searchHints.albums"
              :key="`album-${item.id}-${i}`"
              :class="{ active: $route.path === `/collection/albums/${item.id}` }"
            >
              <div class="poster">
                <img
                  :data-src="item.cover"
                  src="/static_files/no-poster.png"
                  alt=""
                  class="lazyload"
                >
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span style="text-transform: uppercase;">{{ item.singer }}</span>
              </p>
              <nuxt-link :to="`/collection/albums/${item.id}`"></nuxt-link>
            </li>
          </ul>
        </div>
        <div v-if="searchHints.tracks.length">
          <h4>{{ lang('tracks', 'ru') }}</h4>
          <ul>
              <!-- @click="startPlay(item.id)" -->
            <li
              v-for="(item, i) in searchHints.tracks"
              :key="`track-${item._id}-${i}`"
              :class="{ active: $route.path === `/track/${item._id}` }"
            >
              <div class="poster">
                <img
                  :data-src="item.img"
                  src="/static_files/no-poster.png"
                  alt=""
                  class="lazyload"
                >

                <div
                  v-if="$route.path === `/track/${item._id}`"
                  id="bars"
                >
                  <div class="wrapper">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                  </div>
                </div>
              </div>
              <p>
                <strong>{{ item.title }}</strong>
                <span style="text-transform: uppercase;">{{ item.artist }}</span>
              </p>
              <nuxt-link :to="`/track/${item._id}`"></nuxt-link>
            </li>
          </ul>
          <div class="show-more"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  watch: {
    // route() {
    //   this.searchQuery = ''
    //   this.searchHints = {
    //     artists: [],
    //     tracks: [],
    //   }
    // }
    searchQuery(val) {
      const elem = document.querySelector('.search .datalist')
      if (!elem) return

      if (val) {
        if (!this.addEvent) {
          this.addEvent = true
          elem.addEventListener('scroll', this.showMore)
        }
      } else {
        this.addEvent = false
        elem.removeEventListener('scroll', this.showMore)
      }
    }
  },
  computed: {
    ...mapState(['player']),
    route() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      searchQuery: '',
      searchHints: {
        artists: [],
        albums: [],
        tracks: [],
        collections: [],
        genres: [],
      },
      page: 1,
      lastPage: false,
      loading: false,
      addEvent: false,
    }
  },
  methods: {
    showMore() {
      console.log('showMore')
      if (this.lastPage || this.loading) return

      const elem = document.querySelector('.search .show-more')
      if (!elem) return
      
      const doc = document.documentElement.clientHeight
      const visible = doc - elem.getBoundingClientRect().top
      if (visible > -(doc / 2)) {
        this.loading = true

        this.getSearchHints(++this.page)
      }
    },
    startPlay(trackId) {
      this.player.api("playlist", this.searchHints.tracks)

      this.player.api("play", `id:${trackId}`)
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.page = 0
          this.lastPage = false
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const url = `/api/searchhints?value=${encodeURIComponent(this.searchQuery)}&page=${this.page}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {

        if (this.page > 1) {
          const tracks = this.searchHints.tracks.concat(data.tracks)

          this.searchHints.tracks = tracks
        } else {
          this.searchHints = data
        }

        if (!data.tracks.length) {
          this.lastPage = true
        }
      }

      this.loading = false
    }
  }
}
</script>

<style lang="stylus">
.search
  position: relative
  margin-bottom: 20px
  h4
    margin-left: 20px
  .loupe
    display: inline-block
    position: absolute
    width: 14px
    height: 14px
    top: 10px
    left: 8px
    background-image: url('/static_files/icons/loupe.png')
    background-size: 14px
    z-index: 1
  input
    position: relative
    width: 100%
    padding: 5px 14px 5px 30px
    font-weight: 400
    color: #000
    background-color: #ccc
    border: none
    border-radius: 4px
    &::placeholder
      color: rgba(0, 0, 0, 0.35)
  .clr
    position: absolute
    top: 8px
    right: 8px
    width: 18px
    height: 18px
    background-image: url('/static_files/icons/cancel-dark.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: 6px
    background-color: #3d3d3d
    border-radius: 50%
    z-index: 1
    opacity: 0.5
    transition: opacity 0.2s ease
    cursor: pointer
    &:hover
      opacity: 1
  .datalist
    position: absolute
    width: 100%
    color #000
    background-color #fff
    border-radius: 4px
    box-shadow 0 0 10px 0px rgba(0, 0, 0, 0.2)
    z-index 4
    max-height calc(100vh - 80px)
    overflow auto
    -webkit-overflow-scrolling touch
    &::-webkit-scrollbar-track
      width: 4px
      background #fff
    &::-webkit-scrollbar
      width: 4px
    &::-webkit-scrollbar-thumb
      background #fff
    &:hover::-webkit-scrollbar-thumb
      background #ccc
    .no-match
      padding 20px
      a
        color #fff
    ul
      list-style none
      padding: 0px 8px
      li
        display grid
        grid-template-columns 40px auto
        grid-gap 0px 10px
        align-items center
        padding 5px 8px 4px 8px
        border-bottom: 1px solid #ccc
        cursor pointer
        position: relative
        &:hover
          background: #eee
          border-color: #eee
        &.active
          color: #fff
          background: #f9243b
          border-color: #f9243b
        a
          position: absolute
          top: 0px
          left: 0px
          width: 100%
          height: 100%
          z-index: 1
        .poster
          position relative
          width 40px
          height 40px
          background-color: #eee
          background-image: url('/static_files/no-cover.jpg')
          background-size: 40px
          border-radius: 4px
          overflow hidden
          img
            width 100%
            min-height 100%
            object-fit cover
          #bars
            width: 40px
            height: 40px
        p
          strong
            display block
            width 100%
            font-size 1.2rem
            border none
          span
            font-size 1rem
            text-transform lowercase
            opacity 0.75
      
</style>
